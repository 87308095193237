import React, { useCallback, type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

import {
	SSRMouseEventWrapper,
	SSR_NAV_CUSTOMIZE_SIDEBAR_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { useRouteDataRef } from '@confluence/route-manager';

import { CustomizeIconComponent } from '../GlobalNavigationIcons';
import { i18n } from '../globalNavigationTranslations';

import type { GlobalItemProps } from './globalItemProps';
import { useGlobalItemVisibility } from './useGlobalItemVisibility';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireCustomizeSidebarClickedAnalytics,
);

export const CustomizeSidebarItem: FC<GlobalItemProps> = ({
	isHidden,
	peekingId,
	setPeekingId,
	setIsCustomizeSidebarDialogVisible,
}) => {
	const routeDataRef = useRouteDataRef();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const onClick = useCallback(() => {
		setIsCustomizeSidebarDialogVisible(true);
		void fireClickAnalytics();
	}, [fireClickAnalytics, setIsCustomizeSidebarDialogVisible]);

	const shouldHide = useGlobalItemVisibility(
		'customize',
		false, // will never be "selected" if in the more menu
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_CUSTOMIZE_SIDEBAR_BUTTON_METRIC}>
			<MenuButtonItem elemBefore={CustomizeIconComponent} onClick={onClick}>
				<FormattedMessage {...i18n.customize} />
			</MenuButtonItem>
		</SSRMouseEventWrapper>
	);
};
